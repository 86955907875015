<template>
  <div class="train_category_page">
    <div class="top">
      <div class="title">基础培训</div>
    </div>
    <div class="list">
      <div
        class="item tab"
        @click="toTrainList(item)"
        v-for="item in list"
        :key="item.pid"
      >
        <img :src="constant.URL + item.image" alt="" />
        <div class="desc">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainCategory",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.api.train
        .findTCCByPid({ pid: this.$route.query.pid })
        .then((res) => {
          this.list = res.data;
          console.log("基础培训", this.list);
        });
    },
    toTrainList(item) {
      console.log("item", item);
      this.$router.push({
        path: "/trainList",
        query: {
          categoryVidoId: item.id,
          welfareTrain: this.$route.query.welfareTrain,
          title: item.title,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.train_category_page {
  width: 100%;
  margin: 100px auto 0;

  .top {
    width: 100%;
    @include flex();
    border-bottom: solid 4px #f5d1d6;

    .title {
      text-align: left;
      font-size: 30px;
      line-height: 30px;
      font-weight: bold;
      color: #cb1b31;
      width: 1350px;
      margin: 0 auto;
      padding-bottom: 20px;
    }
  }

  .list {
    width: 1400px;
    margin: 0 auto;
    @include flex(row, flex-start, center);
    flex-wrap: wrap;

    .item {
      width: 300px;
      box-sizing: border-box;
      @include flex(column, flex-start, center);
      margin: 25px 25px;

      img {
        width: 100%;
      }

      .desc {
        font-size: 18px;
        color: #777;
        line-height: 20px;
        margin-top: 0.2rem;
        transition: all 0.3s;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
}
</style>